import { Component } from '@angular/core';

@Component({
  selector: 'app-smm-pricing',
  templateUrl: './smm-pricing.component.html',
  styleUrls: ['./smm-pricing.component.scss']
})
export class SmmPricingComponent {

}
