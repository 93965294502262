import { Component } from '@angular/core';

@Component({
  selector: 'app-ppc-pricing',
  templateUrl: './ppc-pricing.component.html',
  styleUrls: ['./ppc-pricing.component.scss']
})
export class PpcPricingComponent {

}
