import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent {
  formData = {
    name: '',
    phone: '',
    message: '',
    serviceType: '',
    subject: '',
    email: '',
    mailto: 'support@hariwebinfotech.com'
  }
  constructor(private http: HttpClient,) { }
  successNotification() {
      Swal.fire("Message Sent Successfully! Our Team Will Contact You Shortly");
    }
  ngOnInit(): void {}
  resetForm(){
      this.formData=
      {
        name: '',
        phone: '',
        message: '',
        serviceType: '',
        subject: '',
        email: '',
        mailto: 'support@hariwebinfotech.com'
      }
    }
  // Tabs
  currentTab = 'tab1';
  switchTab(event: MouseEvent, tab: string) {
      event.preventDefault();
      this.currentTab = tab;
  }
  onSubmit(){
    // document.getElementById("btn-spiner").click();
   
    const apiUrl = ' https://sub.hariwebinfotech.com/public/api/query';
    if(this.formData.email===''){
      this.formData.email="N/A";
    }
     if(this.formData.phone===''){
  this.formData.phone='N/A';
    }
     if(this.formData.subject===''){
      this.formData.subject='N/A';
        }
        if(this.formData.serviceType===''){
          this.formData.serviceType='N/A';
            }
    this.http.post(apiUrl,this.formData).subscribe(
      (response)=> {
        
        
     this.successNotification();
    //  document.getElementById('spinner').style.display='none';
        console.log('emailsend');
      
        this.resetForm();
        
      },(error)=>{
        console.log('error',error);
      }
    )
  }


}
