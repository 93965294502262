<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Pricing</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>What We Offer</span>
            <h2>Our Pricing Plan For You</h2>
          
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>GOOGLE ADS</h3>
                        <p>Starting $179.99</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Google Ads Account Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Targeted Keywords Research
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Landing Page Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Bid Strategy
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            A/B Testing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Campaign Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Daily Report
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                    <strong class="popular">Popular</strong>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>BING ADS</h3>
                        <p>Starting $179.99</p>
                    </div>

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Microsoft Ads Account Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Targeted Keywords Research
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Landing Page Optimization

                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                             Ad Creation    
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Bid Strategy
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Bid Strategy
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Campaign Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Daily Report
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>

           

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3> Dedicated Account Manager</h3>
                        <p>Starting $179.99</p>
                    </div>
                   
                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Facebook Ads Account Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Interest & Behaviour Based Targeting
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Landing Page Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Ad Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Bid Strategy
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            A/B Testing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Campaign Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Followers & Connections
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Campaign Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                    </ul>

                    <a class="box-btn"data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>LINKEDIN ADS</h3>
                        <p>Starting $179.99</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            LinkedIn Ads Account Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Industry Type & Job Level Based
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Targeting
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Landing Page Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Ad Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Bid Strategy
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Bid Strategy
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Campaign Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Daily Report
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>YOUTUBE ADS</h3>
                        <p>Starting $179.99</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Google Ads Account Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Channel Based Targeting
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Video Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Ad Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Bid Strategy
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Content & Hashtag Management

                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Campaign Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Daily Report
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                        <li>
                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>INSTAGRAM ADS</h3>
                        <p>Starting $179.99</p>
                    </div>
                  

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Instagram Ads Account Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Interest & Behaviour Based Targeting
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Landing Page Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Ad Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Bid Strategy
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            A/B Testing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Campaign Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Daily Report
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Social Media Ad Campaigns Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>