import { Component, OnInit, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class NavbarComponent implements OnInit {
    formData = {
        name: '',
        phone: '',
        message: '',
        serviceType: '',
        subject: '',
        email: '',
        mailto: 'info@webspaceinc.org'
      }
    location: any;
    navbarClass: any;

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    constructor(
        private router: Router,
        private http: HttpClient,
        location: Location
       
    ) {
        this.router.events
        .subscribe((event) => {
            if ( event instanceof NavigationEnd ) {
                this.location = this.router.url;
                if (this.location == '/home-three'){
                    this.navbarClass = 'navbar-area three';
                } else {
                    this.navbarClass = 'navbar-area';
                }
            }
        });
    }
    successNotification() {
        Swal.fire("Message Sent Successfully! Our Team Will Contact You Shortly");
      }

    ngOnInit(): void {}
    resetForm(){
        this.formData=
        {
          name: '',
          phone: '',
          message: '',
          serviceType: '',
          subject: '',
          email: '',
          mailto: 'info@webspaceinc.org'
        }
      }

    // Navbar Sticky
    isSticky: boolean = false;
    @HostListener('window:scroll', ['$event'])
    checkScroll() {
        const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        if (scrollPosition >= 50) {
            this.isSticky = true;
        } else {
            this.isSticky = false;
        }
    }
    onSubmit(){
        // document.getElementById("btn-spiner").click();
       
        const apiUrl = ' https://sub.hariwebinfotech.com/public/api/query';
        if(this.formData.email===''){
          this.formData.email="N/A";
        }
         if(this.formData.phone===''){
      this.formData.phone='N/A';
        }
         if(this.formData.subject===''){
          this.formData.subject='N/A';
            }
            if(this.formData.serviceType===''){
              this.formData.serviceType='N/A';
                }
        this.http.post(apiUrl,this.formData).subscribe(
          (response)=> {
            
            
         this.successNotification();
        //  document.getElementById('spinner').style.display='none';
            console.log('emailsend');
          
            this.resetForm();
            
          },(error)=>{
            console.log('error',error);
          }
        )
      }

}