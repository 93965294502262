<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Portfolio</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Portfolio</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<section class="home-case ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have a Look Our Work Showcase</h2>
            <p>Each project is a reflection of our team's passion for pushing boundaries and delivering beyond expectations. We take great pride in the collaborations that have allowed us to create digital experiences that leave a lasting impact on users and elevate brands to new heights.</p>
        </div>
        <div class="case">
            <ul class="nav-tabset">
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab1'}">
                    <span (click)="switchTab($event, 'tab1')">
                        All
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab2'}">
                    <span (click)="switchTab($event, 'tab2')">
                        MEDICAL
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab3'}">
                    <span (click)="switchTab($event, 'tab3')">
                        ECOMMERCE
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab4'}">
                    <span (click)="switchTab($event, 'tab4')">
                        FINANCE
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab5'}">
                    <span (click)="switchTab($event, 'tab5')">
                       BLOGGING
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab6'}">
                    <span (click)="switchTab($event, 'tab6')">
                        COMPANY
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab7'}">
                    <span (click)="switchTab($event, 'tab7')">
                        CORPORATE
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab8'}">
                    <span (click)="switchTab($event, 'tab8')">
                        ENTERTAINMENT
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab9'}">
                    <span (click)="switchTab($event, 'tab9')">
                        MANUFACTURER
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab10'}">
                    <span (click)="switchTab($event, 'tab10')">
                        EDUCATIONAL
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab11'}">
                    <span (click)="switchTab($event, 'tab11')">
                        NEW & MAGAZINE
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab12'}">
                    <span (click)="switchTab($event, 'tab12')">
                        TECHNOLOGY
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab13'}">
                    <span (click)="switchTab($event, 'tab13')">
                        BUSINESS
                    </span>
                </li>
                <li class="nav-tab" [ngClass]="{'active': currentTab === 'tab14'}">
                    <span (click)="switchTab($event, 'tab14')">
                        FAISHION & BEAUTY
                    </span>
                </li>
            </ul>
            <div class="tabs-container">
                   <!--------------------------TAB-ALL-------------------------------------->
                <div class="pane" id="tab1"  *ngIf="currentTab === 'tab1'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.alliancetrust.co.uk/"><img src="assets/images/portfolio-1.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>FINANCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.alliancetrust.co.uk/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.alcoa.com/"><img src="assets/images/portfolio-2.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MANUFACTURER WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.alcoa.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://headache.healthcaresuccess.com/"><img src="assets/images/portfolio-3.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL INSTITUTE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://headache.healthcaresuccess.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.fitwise.com.au/"><img src="assets/images/portfolio-4.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.fitwise.com.au/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.lifecare.com.au/"><img src="assets/images/portfolio-5.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL SPORTS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.lifecare.com.au/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.ashfordfinance.com/"><img src="assets/images/portfolio-6.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>FINANCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.ashfordfinance.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.mdsave.com/"><img src="assets/images/portfolio-7.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.mdsave.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.tauedu.org/"><img src="assets/images/portfolio-8.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.tauedu.org/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.medonthego.com/"><img src="assets/images/portfolio-9.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL E-COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.medonthego.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://better.com/" ><img src="assets/images/portfolio-10.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>FINANCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://better.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://pharmeasy.in/"><img src="assets/images/portfolio-11.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL E-COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://pharmeasy.in/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://medpick.in/"><img src="assets/images/portfolio-12.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL E-COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://medpick.in/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.mstreetbank.com/"><img src="assets/images/portfolio-13.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>FINANCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.mstreetbank.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.bblanguages.com/"><img src="assets/images/portfolio-14.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.bblanguages.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.nh.org.au/"><img src="assets/images/portfolio-15.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.nh.org.au/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.ctvnews.ca/"><img src="assets/images/portfolio-16.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>NEWS/ BLOG WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.ctvnews.ca/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.edx.org/"><img src="assets/images/portfolio-17.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>EDUCATIONAL LMS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.edx.org/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a  href="https://www.onlinemedicalstore.com/"><img src="assets/images/portfolio-18.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL E-COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.onlinemedicalstore.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.synergyconsultingifa.com/"><img src="assets/images/portfolio-19.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>FINANCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.synergyconsultingifa.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.ryangroup.org/"><img src="assets/images/portfolio-20.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.ryangroup.org/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.archer.org/"><img src="assets/images/portfolio-21.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.archer.org/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="http://www.globalsmes.org/"><img src="assets/images/portfolio-22.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>BUSINESS WEBSITE</h3></a>
                                    <a target="_blank"  href="http://www.globalsmes.org/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a  href="https://www.bartleby.com/"><img src="assets/images/portfolio-23.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.bartleby.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://globalcoachinginstitute.org/"><img src="assets/images/portfolio-24.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a target="_blank"  href="https://globalcoachinginstitute.org/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.fabbag.com/" ><img src="assets/images/portfolio-25.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>BEAUTY PRODUCTS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.fabbag.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.newu.in/"><img src="assets/images/portfolio-26.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>BEAUTY PRODUCTS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.newu.in/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.se.com/in/en/"><img src="assets/images/portfolio-27.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>E- COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.se.com/in/en/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a  href="https://thenetxperts.com/"><img src="assets/images/portfolio-28.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>TECHNICAL SUPPORT WEBSITE</h3></a>
                                    <a target="_blank"  href="https://thenetxperts.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.helpware.com/"><img src="assets/images/portfolio-29.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>TECHNICAL SUPPORT WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.helpware.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a  href="https://unity-connect.com/"><img src="assets/images/portfolio-30.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>BPO SUPPORT WEBSITE</h3></a>
                                    <a target="_blank"  href="https://unity-connect.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a  href="https://www.paramount.com/"><img src="assets/images/portfolio-31.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>ENTERTAINMENT WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.paramount.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.globalissues.org/"><img src="assets/images/portfolio-32.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>NEWS/ BLOG WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.globalissues.org/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.backgroundjazz.com/"><img src="assets/images/portfolio-33.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>ENTERTAINMENT WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.backgroundjazz.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.doola.com/" ><img src="assets/images/portfolio-34.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>CORPORATE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.doola.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                       
                        
                    </div>
                </div>
                <!----------------TAB-2-------------------------------------------->
                <div class="pane" id="tab2" *ngIf="currentTab === 'tab2'">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a ><img src="assets/images/portfolio-3.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL INSTITUTE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://headache.healthcaresuccess.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.medonthego.com/" ><img src="assets/images/portfolio-9.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL E-COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.medonthego.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.lifecare.com.au/"><img src="assets/images/portfolio-5.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL SPORTS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.lifecare.com.au/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://pharmeasy.in/"><img src="assets/images/portfolio-11.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL E-COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://pharmeasy.in/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://medpick.in/"><img src="assets/images/portfolio-12.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL E-COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://medpick.in/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.nh.org.au/"><img src="assets/images/portfolio-15.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.nh.org.au/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.onlinemedicalstore.com/"><img src="assets/images/portfolio-18.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL E-COMMERCE WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.onlinemedicalstore.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.fitwise.com.au/"><img src="assets/images/portfolio-4.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.fitwise.com.au/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank"  href="https://www.mdsave.com/"><img src="assets/images/portfolio-7.png" alt="case"/></a>
                                </div>
            
                                <div class="content">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank"  href="https://www.mdsave.com/" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <!----------------------TAB-3-------------------------------------->
                <div class="pane" id="tab3" *ngIf="currentTab === 'tab3'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-4---------------------------------->
                <div class="pane" id="tab4" *ngIf="currentTab === 'tab4'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-5---------------------------------->
                <div class="pane" id="tab5" *ngIf="currentTab === 'tab5'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-6---------------------------------->
                <div class="pane" id="tab6" *ngIf="currentTab === 'tab6'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-7---------------------------------->
                <div class="pane" id="tab7" *ngIf="currentTab === 'tab7'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-8---------------------------------->
                <div class="pane" id="tab8" *ngIf="currentTab === 'tab8'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-9---------------------------------->
                <div class="pane" id="tab9" *ngIf="currentTab === 'tab9'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-10---------------------------------->
                <div class="pane" id="tab10" *ngIf="currentTab === 'tab10'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-11---------------------------------->
                <div class="pane" id="tab11" *ngIf="currentTab === 'tab11'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-12---------------------------------->
                <div class="pane" id="tab12" *ngIf="currentTab === 'tab12'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-13---------------------------------->
                <div class="pane" id="tab13" *ngIf="currentTab === 'tab13'">
                    <div class="row case-list">
                      
                    </div>
                </div>
                <!-------------------------TAB-14---------------------------------->
                <div class="pane" id="tab14" *ngIf="currentTab === 'tab14'">
                    <div class="row case-list">
                      
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="case-btn text-center">
            <p>We Have More Amazing Cases. <a routerLink="/portfolio">View More</a></p>
        </div> -->
    </div>
</section>
<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
            <p></p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input [(ngModel)]="formData.name" required [ngModelOptions]="{standalone: true}" type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input [(ngModel)]="formData.email" required [ngModelOptions]="{standalone: true}" type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input [(ngModel)]="formData.phone" required [ngModelOptions]="{standalone: true}" type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input [(ngModel)]="formData.subject" required [ngModelOptions]="{standalone: true}" type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea [(ngModel)]="formData.message" required [ngModelOptions]="{standalone: true}" name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.jpg" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>
