<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Pricing</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>What We Offer</span>
            <h2>Our Pricing Plan For You</h2>
          
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h4>BASIC</h4>
                        <p>Starting $99.99</p>
                    </div>
                    
                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            1 Page Web Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            2 Articles Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Email Structure and Formatting
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            2 Blogs Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website Copywriting Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            SEO Copywriting Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            24x7 Customer Support
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Curating content for Social Media Posts
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Analyzing keywords for SEO to maximize traffic 
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Team of experienced Native English
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Improvisations and revisions as per your request
                        </li>
                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                    <strong class="popular">Popular</strong>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h4>ADVANCE</h4>
                        <p>Starting $299.99</p>
                    </div>

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            5 Pages Web Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            5 articles Article Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Advanced Email Structure and Formatting
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            5 Blogs Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website Copywriting Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            SEO Copywriting Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            24x7 Customer Support
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Curating content for Social Media Posts
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Analyzing keywords for SEO to maximize traffic
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Team of experienced Native English speakers to ensure quality content
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Improvisations and revisions as per your request
                        </li>
                    </ul>

                    <a class="box-btn"data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>

           

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h4>WEBSITE BUSINESS BUILDER</h4>
                        <p>Starting $599.99</p>
                    </div>
                   
                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            All Pages Web Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            10 Articles Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Premium Email Structure and Formatting
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            10 Blogs Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website Copywriting Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            SEO Copywriting Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            24x7 Customer Support
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Curating content for Social Media Posts
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Analyzing keywords for SEO to maximize traffic 
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Team of experienced Native English speakers to ensure quality content
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Improvisations and revisions as per your request
                        </li>
                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>