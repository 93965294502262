<section class="slider-area-2">
    <div class="home-slider">
        <owl-carousel-o [options]="homeSlides">
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-1">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Website Designing Company In USA</h1>
                                            <p>Our websites are not only aesthetically pleasing but also optimized for performance and search engines, giving you a competitive edge in the digital landscape. Whether you need a simple, elegant portfolio site or a complex e-commerce platform, we have the expertise to bring your ideas to life.</p>
                                            <div class="slider-btn text-center">
                                                <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="box-btn">Let’s Talk!</a>
                                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-2">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Digital Agency & Marketing</h1>
                                            <p>Welcome to our Digital Agency & Marketing hub, where creativity meets innovation, and success becomes a reality. As a leading digital agency, we specialize in providing comprehensive and results-driven digital marketing solutions for businesses of all sizes and industries.</p>
                                            <div class="slider-btn text-center">
                                                <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="box-btn">Let’s Talk!</a>
                                                <a routerLink="/about" class="box-btn border-btn">Know More!</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-slider single-slider-bg-3">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="row align-items-center">
                                    <div class="col-lg-12 text-center">
                                        <div class="slider-content one">
                                            <h1>Make Real-Life Connections With IT</h1>
                                            <p>Welcome to the world of IT where we do more than just connect devices; we make real-life connections that bridge the gap between technology and human experiences. As a dedicated IT service provider, our mission is to empower individuals and businesses to embrace the full potential of technology in their daily lives and operations.</p>
                                            <div class="slider-btn text-center">
                                                <a data-bs-toggle="modal" data-bs-target="#staticBackdrop" class="box-btn">Let’s Talk!</a>
                                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</section>

<div class="info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i1.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-info"></i> About Us</h3>
                        <div class="arrow">
                            <a routerLink="/about"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i2.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-support"></i> Our Vision</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-info si-30">
                    <div class="info-img">
                        <img src="assets/images/info/i3.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-goal"></i>Our Goal</h3>
                        <div class="arrow">
                            <a routerLink="/about"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="home-service-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Creating digital solution which is right for your business</h2>
            <p>Our process begins with listening. We take the time to understand your business inside out, gaining valuable insights into your challenges, opportunities, and aspirations. Armed with this knowledge, our team of experts collaborates to design a comprehensive and innovative digital solution that empowers your business to thrive in the digital age.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Advertising and Marketing</h3>
                        <p>Social Media Marketing (SMM), social media optimization (SMO), Paid marketing, and other digital marketing tools are ruling the business world for connecting and engaging with the right audience.</p>
                        <a  class="line-bnt" target="_blank" >Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Software Development</h3>
                        <p>Our team consists of people who hold knowledge and experience in various technologies related to ecommerce software such as MS Commerce server, Magento, WooCommerce, OS commerce, Spree, Able Commerce, etc.</p>
                        <a   class="line-bnt"  target="_blank">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Graphic Design- UI/UX Desinging</h3>
                        <p>A well-crafted logo design is a true identity of a brand and connects people to the brand. Let’s design your brand identity components, websites, apps, and other marketing collateral.</p>
                        <a   class="line-bnt"  target="_blank">Read More</a>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>IT Management</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque vel sit maxime assumenda. maiores, magnam</p>
                        <a   class="line-bnt" target="_blank" target="_blank">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Cyber Security</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque vel sit maxime assumenda. maiores, magnam</p>
                        <a   class="line-bnt" target="_blank">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Wireless Connectivity</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque vel sit maxime assumenda. maiores, magnam</p>
                        <a   class="line-bnt" target="_blank">Read More</a>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>We are popular because of our way of working</h2>
            <!-- <p>At our company, we take pride in our unique way of working, which sets us apart and makes us popular among our clients. Our approach is built on a foundation of creativity, transparency, and unwavering dedication to excellence.</p> -->
        </div>

        <div class="row">
            <!-- <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Research Product</h3>
                        <p>Lorem ipsum dolor sit amet, co nsectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div> -->
            <div class="col-lg-4 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Web Development</h3>
                        <p>Welcome to the world of web development, where creativity meets technology to bring your digital dreams to life. At our web development company, we are passionate about crafting exceptional websites that leave a lasting impression and deliver tangible results for your business.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Digital Marketing</h3>
                        <p>Welcome to the dynamic world of digital marketing, where innovation, strategy, and creativity come together to elevate your brand to new heights. At our digital marketing agency, we specialize in driving meaningful results for your business through data-driven, customer-centric marketing campaigns.</p>
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>App Development</h3>
                        <p>Welcome to the world of app development, where innovation and technology converge to bring your app ideas to life. At our app development company, we are passionate about creating cutting-edge mobile applications that redefine user experiences and drive business growth.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Choose Webspace</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
            <p>Our team is dedicated to delivering nothing short of the best, consistently going above and beyond to exceed expectations. We believe in going the extra mile to ensure that every interaction with us is a positive and memorable experience.</p>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-friends"></i>
                    </div>
                    <h3>Understanding</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Best Quality</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-chip"></i>
                    </div>
                    <h3>Technology</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-customer-service"></i>
                    </div>
                    <h3>Support 24/7</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-like"></i>
                    </div>
                    <h3>Expert Team</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-coin"></i>
                    </div>
                    <h3>Price Oriented</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Updated Technology</h2>
                    <p>In the fast-paced world of technology, keeping up with the latest advancements is crucial for IT companies to maintain a competitive edge. Embracing and implementing updated technology is not only a necessity but also a strategic move that can propel businesses towards success.</p>
                   <p> One of the primary advantages of using updated technology is the significant boost it provides to the company's efficiency and productivity. New tools, software, and processes are often designed to streamline workflows, automate repetitive tasks, and optimize resource allocation. </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                    <a data-bs-toggle="modal" class="box-btn ms-3" data-bs-target="#staticBackdrop">Schedule a Meeting</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup -->
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src="https://www.youtube.com/embed/XNQk1qt__Vw" title="Setup and Local Installation - React Nextjs Online Yoga Coaching &amp; Training Courses System" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div>


<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/1.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Steven Jony</h3>
                        <span>CEO of Company</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/client/2.jpg" alt="img">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem Ipsum is simply dummy text of the printing Quis suspendisse typesetting ipsum dolor sit amet,</p>
                        <h3>Omit Jacson</h3>
                        <span>Company Founder</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->

<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have A Look Our Work Showcase</h2>
            <p>In our showcase, you'll discover a diverse range of projects that highlight our capabilities in various industries and disciplines. From stunning website designs that captivate audiences to powerful mobile apps that revolutionize user experiences, our work speaks for itself.</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.tauedu.org/"><img src="assets/images/portfolio-8.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a href="https://www.tauedu.org/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.ashfordfinance.com/"><img src="assets/images/portfolio-6.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                   <h3>FINANCE WEBSITE</h3>
                                    <a target="_blank" href="https://www.ashfordfinance.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.mdsave.com/"><img src="assets/images/portfolio-7.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.mdsave.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.lifecare.com.au/"><img src="assets/images/portfolio-5.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL SPORTS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.lifecare.com.au/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.fitwise.com.au/"><img src="assets/images/portfolio-4.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.fitwise.com.au/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://headache.healthcaresuccess.com/"><img src="assets/images/portfolio-3.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL INSTITUTE WEBSITE</h3></a>
                                    <a target="_blank" href="https://headache.healthcaresuccess.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">class="line-bnt" target="_blank"
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.alcoa.com/"><img src="assets/images/portfolio-2.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MANUFACTURER WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.alcoa.com/" class="line-bnt" target="_blank" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.alliancetrust.co.uk/"><img src="assets/images/portfolio-1.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>FINANCE WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.alliancetrust.co.uk/" class="line-bnt" target="_blank" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>


<section class="home-contact-area home-2-contact ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
            <p></p>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input [(ngModel)]="formData.name" required [ngModelOptions]="{standalone: true}" type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input [(ngModel)]="formData.email" required [ngModelOptions]="{standalone: true}" type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input [(ngModel)]="formData.phone" required [ngModelOptions]="{standalone: true}" type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input [(ngModel)]="formData.subject" required [ngModelOptions]="{standalone: true}" type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea [(ngModel)]="formData.message" required [ngModelOptions]="{standalone: true}" name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.jpg" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>

