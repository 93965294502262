import { Component } from '@angular/core';

@Component({
  selector: 'app-cw-pricing',
  templateUrl: './cw-pricing.component.html',
  styleUrls: ['./cw-pricing.component.scss']
})
export class CwPricingComponent {

}
