<!-- <header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="assets/images/logo.png" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+1123456789"><i class="bx bxs-phone-call"></i> +1-442-259-2040</a></li>
                        <li><a href="mailto:hello@aiva.com"><i class="bx bxs-envelope"></i> support@webspaceinc.org</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                        <li><a href="#" target="_blank"> <i class="bx bxl-youtube"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header> -->



<div class="{{navbarClass}}">
    <div
        class="main-nav"
        [ngClass]="{'sticky': isSticky}"
    >
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/logo.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class=" logo-li">
                    <a routerLink="/"><img src="assets/images/logo.png" alt="logo" width="100%"></a>
                </div>
                <div class="collapse navbar-collapse mean-menu justify-content-end" id="navbarSupportedContent">
                    
                    <ul class="navbar-nav text-left">
                        <!-- <li> <a routerLink="/"><img src="assets/images/logo.png" alt="logo" width="50%"></a></li> -->
                        <li class="nav-item">
                            <a href="javascript:void(0)" routerLink="/" class="nav-link ">Home</a>

                            <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>
                            </ul> -->
                        </li>
                        <li class="nav-item"><a routerLink="/portfolio" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Portfolio</a></li>

                        
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/web-development" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web Development</a></li>

                                <li class="nav-item"><a routerLink="/social-media-marketing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Social Media Marketing</a></li>

                                <li class="nav-item"><a routerLink="/ppc-management" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">PPC Management</a></li>

                                <li class="nav-item"><a routerLink="/content-writing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Content Writer</a></li>
                              
                                
                            </ul>
                        </li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pricing <i class="icofont-rounded-right"></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/pricing/web-development" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web Development</a></li>

                                <li class="nav-item"><a routerLink="/pricing/social-media-marketing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Social Media Marketing</a></li>

                                <li class="nav-item"><a routerLink="/pricing/ppc-management" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">PPC Management</a></li>

                                <li class="nav-item"><a routerLink="/pricing/content-writing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Content Writer</a></li>
                                
                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Services</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/solutions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions</a></li>

                                <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions Details</a></li>
                                <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions Details</a></li>
                                <li class="nav-item"><a routerLink="/solutions-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Solutions Details</a></li>
                            </ul>
                        </li> -->

                        <!-- <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Case Studies</a>
                            
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/case-studies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies</a></li>

                                <li class="nav-item"><a routerLink="/case-studies-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Case Studies Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Blog</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Blog Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Pages</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gallery</a></li>

                                <li class="nav-item"><a routerLink="/testimonials" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Testimonials</a></li>

                                <li class="nav-item"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                <li class="nav-item"><a routerLink="/sign-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign In</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Error 404</a></li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms & Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>
                            </ul>
                        </li> -->
                        <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
                        </li>

                        <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact </a></li>
                    </ul>
                </div>
                <!-- <div class="nav-right">
                    <form>
                        <div class="input-group">
                            <input type="text" class="form-control search" placeholder="Search..." />
                        </div>

                        <button type="submit">
                            <i class="bx bx-search"></i>
                        </button>
                    </form>
                </div> -->
                <div class="nav-btn">
                    <button data-bs-toggle="modal" data-bs-target="#staticBackdrop"  type="button" class="box-btn">
                       Get Started
                      </button>
                </div>
            </nav>
        </div>
    </div>
</div>


  

  
  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Schedule a Meeting</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form  (ngSubmit)="onSubmit()">
                <div class="row">
                    <div class="col-md-12 col-sm-6 mt-1">
                        <div class="form-group">
                            <label for="name" class="name">Name</label>
                            <input [(ngModel)]="formData.name" required [ngModelOptions]="{standalone: true}" type="name" name="name" id="name" class="form-control" placeholder="">
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-6 mt-1">
                      <div class="form-group">
                          <label for="number" >Mobile Number</label>
                          <input [(ngModel)]="formData.phone" required [ngModelOptions]="{standalone: true}" type="number" name="number" id="number" class="form-control" placeholder="">
                      </div>
                  </div>
                    <div class="col-md-12 col-sm-6 mt-1">
                        <div class="form-group">
                            <label for="email">Email</label>
                            <input [(ngModel)]="formData.email" required [ngModelOptions]="{standalone: true}"  type="email" name="email" id="email" class="form-control" placeholder="">
                        </div>
                    </div>
                    <div class="col-md-12 col-sm-6 mt-1">
                      <div class="form-group">
                          <label for="service">Service</label>
                          <select [(ngModel)]="formData.serviceType" required [ngModelOptions]="{standalone: true}" class="form-select form-control" aria-label="Default select example">
                              <option >Select</option>
                              <option value="Website Designing">Web Development</option>
                              <option value="App Development">Social Media Marketing</option>
                              <option value="Software Development">PPC Management</option>
                              <option value="Graphic Designing">Content Writer</option>
                            </select>
                      </div>
                    </div>
                    
                    <div class="col-md-12 col-sm-6 mt-1">
                        <div class="form-group">
                            <label for="message-text" class="col-form-label">Message</label>
                        <textarea  [(ngModel)]="formData.message" required [ngModelOptions]="{standalone: true}" class="form-control message-text" id="message-text"></textarea>
                        </div>
                    </div>
                </div>
               
             
        
        <div class="modal-footer">
          <button type="submit" class="box-btn" data-bs-dismiss="modal">Submit</button>
        
        </div>
    </form>
</div>
      </div>
    </div>
  </div>