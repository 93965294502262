<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Pricing</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>What We Offer</span>
            <h2>Our Pricing Plan For You</h2>
          
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>ALMOST FREE</h3>
                        <p>$99 - $399</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Domain name
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            1-3 Pages
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Web Hosting (Shared Hosting 10GB)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Business Email Address
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Logo Design(2 Samples)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Images (4 Stock Images)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            WIX, WordPress
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            eCommerce (WooCommerce only)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Mobile Responsive Web Design    
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Fully SEO Optimised
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            User-Friendly Navigation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Graphic Work (Custom Graphics & Animation)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            No SSL Included
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Delivery Time (7-14 Days)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Good For Small Business
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                    <strong class="popular">Popular</strong>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>WEBSITE STARTER</h3>
                        <p>$399 - $999</p>
                    </div>

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Domain name
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            5-10 Pages
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Web Hosting (Shared Hosting 10GB)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Business Email Address
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Logo Design(10 Samples)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Images (16 Stock Images)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Shopify, WIX, WordPress (Any CMS)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            eCommerce (WooCommerce,Shopify)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Mobile Responsive Web Design    
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Fully SEO Optimised
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            User-Friendly Navigation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Graphic Work (Custom Graphics & Animation)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            No SSL Included
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Delivery Time (7-14 Days)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Good For Small Business
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>

           

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>WEBSITE BUSINESS BUILDER</h3>
                        <p>$99 - $1499</p>
                    </div>
                   

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Domain name
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            5-10 Pages
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Web Hosting (Shared Hosting 10GB)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Business Email Address
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Logo Design(10 Samples)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Images (16 Stock Images)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Shopify, WIX, WordPress (Any CMS)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            eCommerce (WooCommerce,Shopify)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Mobile Responsive Web Design    
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Fully SEO Optimised
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            User-Friendly Navigation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Graphic Work (Custom Graphics & Animation)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            No SSL Included
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Delivery Time (7-14 Days)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Good For Small Business
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>PROFESSIONAL BUSINESS WEBSITE</h3>
                        <p>$1499 - $1999</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Domain name
                        </li>
                       
                        <li>
                            <i class="bx bx-badge-check"></i>
                            10-20 Pages
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Web Hosting (Shared/Dedicated Hosting)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Business Email Address
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Logo Design (Multiple Choices)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Images (25 Stock Images)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Shopify, WIX, WordPress (Any CMS)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            eCommerce (WooCommerce, Shopify, Magento, OPencart & More)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Mobile Responsive Web Design    
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Fully SEO Optimised
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            User-Friendly Navigation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Graphic Work (Custom Graphics & Animation)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            SSL Security
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Delivery Time (21-28 Days)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Other Standard Features
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>BUSINESS BOOSTER WEBSITE</h3>
                        <p>$1999 - $4999</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Domain name
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dynamic Pages
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Business Email Address
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Logo Design (Multiple Choices)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Images (30 Stock Images)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Shopify, WIX, WordPress (Any CMS)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            eCommerce (WooCommerce, Shopify, Magento, OPencart & More)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Mobile Responsive Web Design  
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Fully SEO Optimised
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            User-Friendly Navigation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Graphic Work (Custom Graphics & Animation)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            No SSL Included
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Delivery Time (21-28 Days)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Other Standard Features 
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>CUSTOM WEBSITE DEVELOPMENT</h3>
                        <p>Get Price</p>
                    </div>
                  

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Domain name
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Website/ Web Based Application  
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Web Hosting (Dedicated Hosting)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Any CMS Development
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            eCommerce (WooCommerce, Shopify, Magento, OPencart & More)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Frontend & Backend Development
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Database Management
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Fully SEO Optimized 
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Logo Design
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Images (Stock & Custom Images)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Business Email Address
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Content & Blog Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            SSL Security
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            User-Friendly Navigation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Graphic Work (Custom Graphics & Animation)
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Other Standard Features 
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>