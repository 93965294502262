import { Component } from '@angular/core';

@Component({
  selector: 'app-wd-pricing',
  templateUrl: './wd-pricing.component.html',
  styleUrls: ['./wd-pricing.component.scss']
})
export class WdPricingComponent {

}
