<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Social Media Marketing</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Social Media Marketing</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="technology-content">
                    <p><span>SMM</span></p>
                    <h2>Social Media Marketing</h2>
                    <p>If people don’t know about your business, they can’t be your customers. Social Media Marketing helps you boost your visibility and attract potential buyers, help reach a larger audience. With the correct Social Media Marketing strategies, you can attain higher revenues targets and keep building profits in the long run.</p>
                    <p>Content Marketing keeps customers engaged helping you retain your already existing customer base and gain loyalty. Social Media Marketing can increase your chances of selling and increasing revenue! Why only sell locally when you have the world of Social Media at your feet. SMM is cost-effective and significantly increases your SEO rankings.</p>
                   
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                    <a data-bs-toggle="modal" class="box-btn ms-3" data-bs-target="#staticBackdrop">Schedule a Meeting</a>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

        </div>
    </div>
</section>
<section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Choose Webspace</span>
            <h2>Connecting businesses with their target audience through strategic social media engagement.</h2>
            <p>In the era of influencer marketing, social media platforms have become a marketplace for collaborations with key influencers and micro-influencers who have a dedicated following in specific niches. Leveraging their influence can amplify brand messages and expand the brand's reach to new audiences.</p>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-friends"></i>
                    </div>
                    <h3>Understanding</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Best Quality</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-chip"></i>
                    </div>
                    <h3>Technology</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-customer-service"></i>
                    </div>
                    <h3>Support 24/7</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-like"></i>
                    </div>
                    <h3>Expert Team</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-coin"></i>
                    </div>
                    <h3>Price Oriented</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have A Look Our Work Showcase</h2>
            <p>In our showcase, you'll discover a diverse range of projects that highlight our capabilities in various industries and disciplines. From stunning website designs that captivate audiences to powerful mobile apps that revolutionize user experiences, our work speaks for itself.</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.tauedu.org/"><img src="assets/images/portfolio-8.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a href="https://www.tauedu.org/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.ashfordfinance.com/"><img src="assets/images/portfolio-6.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                   <h3>FINANCE WEBSITE</h3>
                                    <a target="_blank" href="https://www.ashfordfinance.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.mdsave.com/"><img src="assets/images/portfolio-7.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.mdsave.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.lifecare.com.au/"><img src="assets/images/portfolio-5.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL SPORTS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.lifecare.com.au/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.fitwise.com.au/"><img src="assets/images/portfolio-4.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.fitwise.com.au/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://headache.healthcaresuccess.com/"><img src="assets/images/portfolio-3.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL INSTITUTE WEBSITE</h3></a>
                                    <a target="_blank" href="https://headache.healthcaresuccess.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">class="line-bnt" target="_blank"
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.alcoa.com/"><img src="assets/images/portfolio-2.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MANUFACTURER WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.alcoa.com/" class="line-bnt" target="_blank" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.alliancetrust.co.uk/"><img src="assets/images/portfolio-1.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>FINANCE WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.alliancetrust.co.uk/" class="line-bnt" target="_blank" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>
