<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Pricing</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>What We Offer</span>
            <h2>Our Pricing Plan For You</h2>
          
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>FACEBOOK MARKETING</h3>
                        <p>Starting $99.99</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Facebook Business Page
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Page Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Facebook Marketplace Set-up
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Traffic Building Strategies & Hashtags
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Post Writing Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Facebook Ad Campaign Set-up
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Creative Facebook Banners & Stories
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Likes & Followers
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Analytics progress report 
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Keyword Based Content for Postings
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                    <strong class="popular">Popular</strong>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>INSTAGRAM MARKETING</h3>
                        <p>Starting $99.99</p>
                    </div>

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Instagram Business Page
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Page Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Instagram Marketplace Set-up

                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Traffic Building Strategies & Hashtags
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Post Writing Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Instagram Ad Campaign Set-up
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Creative Instagram Posts & Stories
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Followers
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Analytics progress report 
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Creation Of Hash Tags
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                           Creation Of Hash Tags
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Monthly Activity Report Creation Of Hash Tags
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>

           

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>LINKEDIN MARKETING</h3>
                        <p>Starting $99.99</p>
                    </div>
                   
                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            LinkedIn Business Page
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Keyword Based Content For Posting
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Adding Thematic Connections
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Traffic Building Strategies & Hashtags
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Business Page Content Writing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Page Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Promoting Brand In Targetted Audience
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Followers & Connections
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Followers & Connections
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Monthly Activity Report
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Campaign Set-up
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>YOUTUBE MARKETING</h3>
                        <p>Starting $99.99</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Youtube Channel Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Youtube Ad Account Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Channel Design, Image, Customizing
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Custom Thumbnail Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Video Animation & Graphics
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Video Animation & Graphics
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Youtube Keyword Search
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Youtube Backlink Creation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Analytics progress report
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Subscribers & Likes
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Monthly Activity Report
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>TWITTER MARKETING</h3>
                        <p>Starting $99.99</p>
                    </div>
                    

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Twitter Business Page
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Page Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Tweet Writing Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Traffic Building Strategies & Hashtags  
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Post Writing Services
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Ad Campaign Set-up

                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Creative Twitter Tweets & Stories   
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Key Influencers Tracking
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Key Influencers Tracking
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing">
                    <div class="pricing-top-heading">
                        <h3>BUSINESS BOSTER SERVICES</h3>
                        <p>Starting $499.99</p>
                    </div>
                  

                    <ul>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Any 5 Social Media Platforms
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Complete Marketing Solution
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Customised Business Strategy Execution
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Organic Traffic Building Likes & Followers
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            In-depth Competitor Analysis & Implementation
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Ad Campaign Optimization Strategies
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Content Marketing Strategy & Posts
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Community Engagement Management
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Social Media Ad Campaigns Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Social Media Ad Campaigns Optimization
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Dedicated Account Manager
                        </li>
                        <li>
                            <i class="bx bx-badge-check"></i>
                            Weekly Reports
                        </li>

                    </ul>

                    <a class="box-btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >Get Started</a>
                </div>
            </div>
        </div>
    </div>
</section>