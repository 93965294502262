<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Innovative IT Helping Service All Over The World</h2>
                        <p>Webspace INC is a dynamic and forward-thinking company that has been making significant strides in revolutionizing the IT industry. Committed to delivering unparalleled service and solutions, Webspace INC has emerged as a leading player in the global tech arena.
                            With a passion for innovation and a dedication to helping businesses and individuals succeed, the company has been providing exceptional IT services worldwide.

                            <a data-bs-toggle="modal" class="box-btn ms-3" data-bs-target="#staticBackdrop">Schedule a Meeting</a>

                        </p>

                        
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/company/company-img.jpg" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.jpg" alt="choose" />

                    <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" 
                        (click)="openPopup()"
                        >
                            <i class='bx bx-play' ></i>
                        </button>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Updated Technology</h2>
                    <p>In the fast-paced world of technology, keeping up with the latest advancements is crucial for IT companies to maintain a competitive edge. Embracing and implementing updated technology is not only a necessity but also a strategic move that can propel businesses towards success.</p>
                    <p>One of the primary advantages of using updated technology is the significant boost it provides to the company's efficiency and productivity. New tools, software, and processes are often designed to streamline workflows, automate repetitive tasks, and optimize resource allocation. </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                    <a data-bs-toggle="modal" class="box-btn ms-3" data-bs-target="#staticBackdrop">Schedule a Meeting</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Video Popup
<div class="video-popup" *ngIf="isOpen">
    <div class="popup-inner">
        <iframe src=""  allowfullscreen></iframe>
        <button (click)="closePopup()" type="button" class="close-btn">
            <i class='bx bx-x'></i>
        </button>
    </div>
</div> -->

<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>More About Us</span>


                        <h2>Mission & Vision</h2>
                        <p>At Webspace INC, our vision is to create a world where technology empowers individuals and organizations to thrive. Our mission is to harness the power of cutting-edge technology, along with our expertise and experience, to deliver tailored solutions that drive growth, efficiency, and success for our clients across the globe.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i> Comprehensive IT Solutions</li>
                        <li><i class="flaticon-correct"></i> Business Solutions</li>
                        <li><i class="flaticon-correct"></i> Website Development Services</li>
                        <li><i class="flaticon-correct"></i> Technical Support</li>
                        <li><i class="flaticon-correct"></i> Innovation and Research</li>
                        <li><i></i>Complete Digital Marketing Services</li>
                    </ul>
                    <a data-bs-toggle="modal" data-bs-target="#staticBackdrop"  class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/rating.png" alt="img">
                        <p>"Impressed by Webspace Inc's expertise! They delivered an outstanding website that exceeded my expectations. Professional, reliable, and a pleasure to work with!"</p>
                        <h3>Felic</h3>
                        <span>CEO of Multilingual Group</span>
                    </div>
                </ng-template>

                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/rating.png" alt="img">
                        <p> "Webspace Inc nailed it! They created a powerful website that boosts my business. Their team is top-notch - skilled, efficient, and responsive!"</p>
                        <h3>John Hoffman</h3>
                        <span>Manager Cosmic Connections</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="assets/images/rating.png" alt="img">
                        <p> "Webspace Inc brought my vision to life! The website is stunning, functional, and user-friendly. Highly recommend their web development services!"</p>
                        <h3>Neeta Singh</h3>
                        <span>CEO of Neeta Naturals</span>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section> -->