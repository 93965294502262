<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Web Development</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Web Development</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/web-development.jpeg" alt="choose" />

                    <!-- <div class="technology-video">
                        <button type="button" class="video-btn p-0 border-0" (click)="openPopup()">
                            <i class='bx bx-play' ></i>
                        </button>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <p><span>Web Development</span></p>
                    <h2>Get Your Customized Business Solution</h2>
                    <p>Web development as a service provides businesses with tailored website creation, design, and maintenance solutions. It encompasses frontend and backend development, ensuring responsive, secure, and user-friendly sites, offering a comprehensive online presence.</p>
                    
                    <ul>
                        <li>E-commerce Website</li>
                        <li>Health Insurance Website</li>
                        <li>Coporate Website</li>
                        <li>E-commerce Website</li>

                    </ul>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                    <a data-bs-toggle="modal" class="box-btn ms-3" data-bs-target="#staticBackdrop">Schedule a Meeting</a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Choose Webspace</span>
            <h2>Empowering digital experiences through web development.</h2>
            <p>The process of web development starts with designing a clear and intuitive user interface, laying the foundation for an exceptional user experience. This involves creating responsive layouts that adapt seamlessly to different devices and screen sizes, ensuring accessibility for all users. Web developers skillfully blend HTML, CSS, and JavaScript to bring life to the static elements, making the content dynamic and interactive.</p>
        </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-friends"></i>
                    </div>
                    <h3>Understanding</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Best Quality</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-chip"></i>
                    </div>
                    <h3>Technology</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-customer-service"></i>
                    </div>
                    <h3>Support 24/7</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-like"></i>
                    </div>
                    <h3>Expert Team</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-coin"></i>
                    </div>
                    <h3>Price Oriented</h3>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-team-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Case Studies</span>
            <h2>Have A Look Our Work Showcase</h2>
            <p>Mastering the Art of Web Development: Building Modern, Interactive, and Scalable Websites"</p>
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.tauedu.org/"><img src="assets/images/portfolio-8.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>EDUCATIONAL WEBSITE</h3></a>
                                    <a href="https://www.tauedu.org/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.ashfordfinance.com/"><img src="assets/images/portfolio-6.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                   <h3>FINANCE WEBSITE</h3>
                                    <a target="_blank" href="https://www.ashfordfinance.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.mdsave.com/"><img src="assets/images/portfolio-7.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.mdsave.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.lifecare.com.au/"><img src="assets/images/portfolio-5.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL SPORTS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.lifecare.com.au/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.fitwise.com.au/"><img src="assets/images/portfolio-4.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL DOCTORS WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.fitwise.com.au/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://headache.healthcaresuccess.com/"><img src="assets/images/portfolio-3.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MEDICAL INSTITUTE WEBSITE</h3></a>
                                    <a target="_blank" href="https://headache.healthcaresuccess.com/" class="line-bnt" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">class="line-bnt" target="_blank"
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.alcoa.com/"><img src="assets/images/portfolio-2.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>MANUFACTURER WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.alcoa.com/" class="line-bnt" target="_blank" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="col-lg-12 col-sm-12">
                            <div class="single-case">
                                <div class="case-img">
                                    <a target="_blank" href="https://www.alliancetrust.co.uk/"><img src="assets/images/portfolio-1.png" alt="case"/></a>
                                </div>
            
                                <div class="content text-center">
                                    <a><h3>FINANCE WEBSITE</h3></a>
                                    <a target="_blank" href="https://www.alliancetrust.co.uk/" class="line-bnt" target="_blank" target="_blank"><p>View Project Details</p></a>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</section>

